import React, { useState, useEffect, useRef, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

/** Turns a did:pkh into a clean address and chain object */
export default function useRyft() {
  const { ryft, user, setUser, credentials, setCredentials, theme, context, accessRules, hasAccess, connecting, setConnecting, magic, connectModalVis, setConnectModalVis } = useContext(GlobalContext);
  return {
    ryft,
    user,
    setUser,
    credentials,
    setCredentials,
    theme,
    context,
    accessRules,
    hasAccess,
    connecting,
    setConnecting,
    magic,
    connectModalVis,
    setConnectModalVis
  };
}
