import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Ryft, Discussion, UserPopup, Chat, Inbox, defaultTheme, darkTheme } from "./lib";

const ryftOptions = {
  useLit: false,
  node: "https://node1.orbis.club",
  PINATA_GATEWAY: 'https://orbis.mypinata.cloud/ipfs/',
  PINATA_API_KEY: '194337be204670686a63',
  PINATA_SECRET_API_KEY: 'd69ee5685fec8cd9012e9e02d28c6d017d22770de68c703f72eb368537b609bf'
};

let _ryft = new Ryft({
  useLit: false,
  node: "https://node2.orbis.club",
  PINATA_GATEWAY: 'https://orbis.mypinata.cloud/ipfs/',
  PINATA_API_KEY: '194337be204670686a63',
  PINATA_SECRET_API_KEY: 'd69ee5685fec8cd9012e9e02d28c6d017d22770de68c703f72eb368537b609bf'
});

function App() {
  const [theme, setTheme] = useState(defaultTheme);
  const [selectedTheme, setSelectedTheme] = useState("white");

  
  return (
    <div className="App flex flex-col h-screen" style={{paddingTop: 50, padding: 20, backgroundColor: "#22201D", justifyContent: "center", height: "100%", width: '100vw', overflowX: 'hidden'}}>
      <div className="w-full py-5 mt-10">

      </div>
      <div className="w-full justify-center flex">
       
      <div className="flex" style={{paddingTop: 40, maxWidth: 950}}>
      {/* 
      did:pkh:eip155:1:0xc45726c3e91ba4c402e49f761aa164a746180daf
        <UserPopup visible={true} details={docMeta.controllers[0]} />
      <UserPopup visible={true} details={post.creator_details} /> */}
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: '100px' }}
        viewBox="0 0 473 159"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <g fill="#D04421">
              <g transform="translate(.62)">
                <path d="M71.244.01c2.808.095 5.667-.154 8.46.135 2.253.497 4.403.844 6.707.949 8.26 1.535 16.236 4.572 23.604 8.83 16.501 9.726 29.336 26.404 34.733 45.687 1.072 3.384 1.73 6.838 2.336 10.341.9 3.186.303 6.362.91 9.577.396 3.588-.075 7.683-1.507 10.983-2.406 5.064-6.702 8.94-11.268 11.708-6.678 4.23-14.495 6.833-22.013 8.767-4.478 1.138-9.02 1.794-13.535 2.733-2.882.07-5.648.611-8.488 1.054-11.544.377-23.077.407-34.625-.03-2.84-.502-5.61-.92-8.489-1.069-3.633-.82-7.299-1.262-10.913-2.162-8.502-1.943-17.051-4.76-24.611-9.427-4.632-2.962-8.666-6.495-11.073-11.773C-.169 82.571-.202 77.875.246 73.834c.354-2.827.06-5.546.797-8.324 2.174-14.7 8.731-28.64 18.11-39.633 2.388-3.235 5.36-5.56 8.26-8.369C36.377 9.87 47.053 4.314 58.311 1.84c1.628-.432 3.228-.835 4.912-.865 2.76-.109 5.26-1.083 8.03-.954l-.009-.01z"></path>
              </g>
              <g transform="rotate(-180 74.129 79.5)">
                <path d="M.006 66.994c.036-.165.071-.323.095-.488 2.45-10.662 8.936-20.69 18.395-28.68 1.011-.819 2.051-1.612 3.086-2.41 2.854-2.271 6.142-4.25 9.471-6.09 3.086-1.623 6.296-3.152 9.685-4.382 3.17-1.275 6.546-2.264 9.929-3.152 3.686-.888 7.396-1.617 11.207-2.074.993-.216 2.004-.114 3.003-.298 1.123-.26 2.247-.089 3.418-.228.791 0 1.48-.09 2.254-.203 2.2-.02 4.4 0 6.599-.013.624-.025 1.195.177 1.813.177 1.16 0 2.307.121 3.46.146.88.165 1.671.26 2.575.267 5.97.697 11.837 1.909 17.426 3.678 2.325.692 4.53 1.554 6.748 2.417 6.213 2.575 12.063 5.803 17.1 9.615 10.505 7.788 17.93 18.038 21.04 29.016.291.914.494 1.84.696 2.766-2.283-8.1-8.258-15.761-17.1-21.97-1.712-1.32-3.822-2.449-5.749-3.654-10.297-5.847-23.389-9.894-37.236-11.473-1.409-.083-2.711-.393-4.138-.38-1.409-.02-2.747-.248-4.132-.35a581.8 581.8 0 00-9.412-.019c-2.39-.07-4.66.394-7.057.4-1.974.254-3.96.412-5.921.704-13.9 1.934-26.94 6.349-36.832 12.609a52.687 52.687 0 00-6.802 4.833C8.413 51.956 4.53 56.815 2.01 61.844a49.032 49.032 0 00-1.998 5.119c0 .006-.006.012-.012.019l.006.012z"></path>
                <path d="M18.53 26.15c1.01-1.136 2.057-2.234 3.098-3.344 2.866-3.148 6.161-5.896 9.503-8.448 3.099-2.253 6.316-4.379 9.717-6.08 3.182-1.77 6.572-3.147 9.962-4.372 3.693-1.232 7.415-2.24 11.245-2.882.993-.298 2.01-.152 3.01-.412 1.13-.362 2.253-.12 3.43-.317.792 0 1.487-.121 2.26-.28 2.207-.025 4.413 0 6.62-.012.63-.032 1.2.24 1.82.247 1.159 0 2.313.165 3.472.203.88.229 1.671.362 2.587.368 5.989.971 11.876 2.653 17.484 5.11 2.331.958 4.544 2.157 6.774 3.356 6.232 3.574 12.102 8.06 17.157 13.353 4.234 4.341 7.957 9.241 11.108 14.528a60.766 60.766 0 00-6.803-7.115c-1.713-1.663-3.818-3.097-5.739-4.626-10.276-7.394-23.341-12.516-37.156-14.515-1.41-.108-2.706-.501-4.133-.482-1.404-.026-2.742-.317-4.121-.438a421.035 421.035 0 00-9.39-.02c-2.385-.088-4.657.496-7.042.509-1.968.317-3.949.52-5.911.888-13.874 2.444-26.88 8.028-36.752 15.949-2.468 1.866-4.758 3.966-6.791 6.118a56.045 56.045 0 00-3.224 3.617 79.806 79.806 0 017.82-10.89l-.005-.013z"></path>
              </g>
            </g>
            <path
              fill="#F8F6EF"
              d="M203.088 22H246.1c7.167 0 13.386 1.367 18.656 4.1 5.27 2.734 9.29 6.55 12.057 11.45 2.767 4.9 4.15 10.526 4.15 16.876 0 6.233-1.718 11.418-5.156 15.555-3.437 4.138-8.109 7.192-14.013 9.163-5.905 1.971-12.636 2.957-20.194 2.957h-18.663V127h-19.85V22zm42.086 48.45c5.517 0 9.643-1.514 12.376-4.543 2.734-3.03 4.1-6.856 4.1-11.481 0-4.585-1.366-8.36-4.1-11.326-2.733-2.967-6.859-4.45-12.376-4.45h-22.237v31.8h22.237zM223.08 82.103c4.281 0 17.354 1.471 27.57.219 9.852-1.209 17.083-4.97 19.695-2.585 5.32 4.859 8.318 11.25 8.993 19.175l2.55 28.088h-19.599l-2.55-25.812c-.36-4.025-1.834-7.292-4.426-9.8-2.591-2.509-5.816-3.763-9.674-3.763H223.08v-5.522zM285.5 51.4h18.737l17.499 51.95 17.414-51.95h18.886L330.374 127l-16.338 2.374L285.498 51.4zm28.566 77.972l16.349-2.397-10.442 30.625h-17.55l10.716-30.625.927 2.397zm60.309-62.447h-14.888V51.4h14.888v-6.987c0-5.067 1.104-9.52 3.312-13.357 2.207-3.837 5.351-6.798 9.43-8.881 4.08-2.084 8.808-3.126 14.183-3.126h13.523v15.525h-11.4c-3.257 0-5.85.973-7.78 2.92-1.929 1.945-2.893 4.543-2.893 7.794V51.4h22.073v15.525H392.75V127h-18.375V66.925zm77.373-38.35V51.4h21.113v15.525h-21.113v32.963c0 3.775.95 6.65 2.85 8.624 1.9 1.976 4.625 2.963 8.175 2.963h10.088V127h-12.214c-8.375 0-15.014-2.331-19.918-6.994-4.904-4.662-7.356-11.135-7.356-19.418V66.925h-14.887V51.4h14.887V28.574h18.375z"
            ></path>
          </g>
        </g>
      </svg>
      

<div>
 <h2 style={{color: 'white', marginTop: 20}}>
 Welcome to the UI Kit Playground
 </h2>
  <p style={{color: 'white', marginTop: 20}}>
  Feel free to play around. Everything is saved to Public Network.<br />
      For privacy, your interactions and posts will be skipped on refresh of page so others will not see.
  </p>
</div>
  
      <div className="flex" style={{paddingTop: 40, width: '97vw', maxWidth: 950, overflowX: 'hidden'}}>
      <Discussion  
            theme="kjzl6cwe1jw14bj8jizr4912cgfgf17fx8qvaf15p2bmmj5pj76c70nr1gae902" 
            context="kjzl6cwe1jw149e95ii6u48obflog6m282k846orp8inqml0oivmzke6l1tzmdd" />
       </div>
      
      </div>
    </div>
  );
}

export default App;
